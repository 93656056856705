import Layout from "../components/layout";
import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import SEO from "../components/seo";
import { fontstack } from "utils/fontstack";
import { type } from "utils/type";

const Wrapper = styled.div`
  position: relative;
  margin: 300px 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Header = styled.h1`
  ${fontstack.primary}
  ${type("titleA")}
`;

const Message = styled.div`
  ${fontstack.secondary}
  ${type("subheaderA")}
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Wrapper>
      <Header>404 NOT FOUND</Header>
      <Message>This page has yet to manifest itself... Shall we go <Link to="/">HOME</Link> first?</Message>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
